
body,
html,
#root,.auth-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;

  .auth-inner {
    width: 450px;
    margin: 5vh auto;
    background: #2c2f2e;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
  }

  h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }

}