@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');


:where(.css-o4oa41)[class^="ant-typography"],
:where(.css-o4oa41)[class*=" ant-typography"],
:where(.css-o4oa41).ant-input,
textarea:where(.css-o4oa41).ant-input {
  font-family: 'Lato', sans-serif;

}

body {
  * {
    font-family: 'Lato', sans-serif;

  }
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

#root {
  .button-main {
    background: #1b8d43;
    &.delete {
      background: #b92222;
    }
  }
}

#root {
  .site-layout-content {
    &::-webkit-scrollbar {
      width: .51em;
    }

    &::-webkit-scrollbar-track {
      background: #333;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
      border-radius: 5px;
    }
  }

  .sidebar,
  .header {
    overflow: hidden;
  }
  .sidebar {
    padding-right: 15px;
  }

  .ant-table-container {
    .ant-table-body {
      &::-webkit-scrollbar {
        height: .51em;
      }

      &::-webkit-scrollbar-track {
        background: #333;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
        border-radius: 5px;
      }
    }

    .ant-table-header {
      &.ant-table-sticky-holder {
        top: -24px !important;
      }
    }


    tbody {
      tr {
        td {
          span {
            text-align: left !important;
          }

          &.ant-table-cell {
            text-align: left !important;
            font-family: 'Lato', sans-serif;
            a {
              text-decoration:none;
              font-weight: 400;
            }

            span {
              font-family: 'Lato', sans-serif;

            }
          }
        }
      }
    }
  }

  .bucket-table-container {
    border: 1px dotted white;
    padding: 8px;
    background: rgba(255, 255, 255, .1);


    .ant-table-body {
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: .51em;
      }

      &::-webkit-scrollbar-track {
        background: #333;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
        border-radius: 5px;
      }
    }
  }
}

/* pxt config --------------------------- */

#rc-tabs-0-panel-tournament-config {
  .ant-card-head {
    border-bottom: none;
  }
  .ant-card-body {
    .ant-row {
      .ant-form-item {
        width: calc(100% / 6);
      }
    }
  }
}
