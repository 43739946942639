
#root, body {
  //.header{
  //  height: 64px;
  //}

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .ant-upload-list, .ant-upload-list-item:not(.ant-upload-list-item-error) .ant-upload-icon .anticon, .ant-upload-list-item .ant-upload-list-item-actions .anticon {
    color: white;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .card-bg{
    background: #2c2e2f
  }

  .sidebar, .header {
    background: #2c2e2f;
    overflow-y: scroll;


    .logo {
      height: 80px;
      width: 100%;
      display: inline-flex;

      img {
        height: 56px;
        width: 56px;
      }
    }

    &.ant-layout-sider-collapsed {
      .logo {
        img {
          height: 48px;
          width: 48px;
        }
      }
    }

    .ant-menu, .ant-menu-sub, .ant-layout, .ant-layout-header, .ant-menu-submenu-popup {
      background: #2c2e2f;

      a {
        text-decoration: none;
      }

      .ant-menu-item-active, .ant-menu-submenu-title:active, .ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
        background: #777777;
      }

      .ant-menu-item-selected {
        background: #989797;
      }

    }
  }

  .bucket-table-container {
    .ant-table, .ant-table-row{
      background: transparent;
    }
    .ant-table-placeholder {
      display: none;
    }
  }

  .ant-menu, .ant-menu-sub, .ant-menu-submenu-popup {
    background-color: #2c2e2f !important;

    a {
      text-decoration: none;
    }

    .ant-menu-item-active, .ant-menu-submenu-title:active {
      background-color: #777777 !important;
    }

    .ant-menu-item-selected {
      background-color: #989797 !important;
    }
  }

  .ant-pagination-item, .ant-pagination-prev:not(.ant-pagination-disabled), .ant-pagination-next:not(.ant-pagination-disabled) {
    background: white;
  }

  .ant-checkbox-inner:after {
    border-color: #2c2e2f;
  }

  .ant-spin-container {
    height: 100%;

  }

  .ant-spin {
    margin: auto;
    position: inherit;
  }

  .ant-typography-copy {
    color: white;
  }

  .ant-btn-default:not(:disabled):hover {
    color: #d9d9d9;
    border-color: #d9d9d9;
  }
  .ant-tabs-tab-disabled{
    display: none;
  }

  .ant-tabs {
    .ant-tabs-tab {
      background: #777777;
      min-width: 200px;
    }
    .ant-tabs-tab-btn {
      color: white;
      margin: auto;
    }
    //.ant-tabs-tabpane-active {
    //  border: 2px white solid;
    //}

    .ant-tabs-nav-more{
      .anticon {
        color: white;
      }
    }

    .ant-switch-checked{
        background-color: rgb(25,135,84);
    }

    .ant-select-clear, .ant-select-arrow {
      color: #2c2f2e;
    }

  }
}
.ant-select-dropdown {
  .ant-select-item-option-selected {
    background-color: rgba(0, 0, 0, 0.1)!important;
  }
}
.modal-custom{

  .ant-btn-default{

    &:hover {
      border-color: #d9d9d9;
      color: black;
    }
    &:active {
      border-color: #d9d9d9;
      color: black;
    }
  }

  &.delete-modal {
    .ant-btn-primary {
      background: red;
      &:hover {
        background: rgba(255, 0, 0, 0.8);
      }
      &:active {
        background: rgba(255, 0, 0, 0.6);
      }
    }
  }

  &.logout-modal {
    .ant-btn-primary {
      background: #2c2e2f;
      &:hover {
        background: rgba(44, 46, 47, 0.8);
      }
      &:active {
        background:  rgba(44, 46, 47, 0.6);

      }
    }
  }
  &.role-modal {
    .ant-btn-primary {
      background: rgb(25,135,84);
      &:hover {
        background:rgb(25,135,84, 0.8);
      }
      &:active {
        background: rgb(25,135,84, 0.6);
      }
    }
  }


  .ant-btn-default {
    background: transparent;
    &:hover {
      background: rgba(82, 82, 82, 0.2);
    }
    &:active {
      background: rgba(82, 82, 82, 0.1);
    }
  }
}





//.sidebar {
//  position: fixed;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  min-height: 100vh !important;
//  z-index: 100;
//  padding: 48px 0 0;
//  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
//
//
//
//}

//#sidebar-wrapper {
//  min-height: 100vh !important;
//  width: 100vw;
//  margin-left: -1rem;
//  -webkit-transition: margin 0.25s ease-out;
//  -moz-transition: margin 0.25s ease-out;
//  -o-transition: margin 0.25s ease-out;
//  transition: margin 0.25s ease-out;
//
//  .sidebar-heading {
//    padding: 0.875rem 1.25rem;
//    font-size: 1.2rem;
//  }
//
//}
//
//#page-content-wrapper {
//  min-width: 0;
//  width: 100%;
//}

