// Here you can add other styles

* {
  box-sizing: border-box;
}

body {
  background: #494949;
  min-height: 100vh;
  display: flex;
}

#root {
  height: 100vh;
  width: 100vw;

  .site-layout-content {
    margin: 24px 16px;
    padding: 24px;
    min-height: 280px;
    max-height: 100vh;
    overflow-y: scroll;
  }

  .button-main {
    background: #2c2e2f;
    width: 6vw;
    min-width: 120px;

    &:hover {
      background: rgba(44, 46, 47, 0.8);
    }

    &:active {
      background: rgba(44, 46, 47, 0.6);
    }

    &.delete {
      background: red;

      &:hover {
        background: rgba(255, 0, 0, 0.8);
      }

      &:active {
        background: rgba(255, 0, 0, 0.6);
      }
    }

  }

  .max-width-100 {
    max-width: 100%;
  }

  .csv-link {
    color: white;
    text-decoration: none;
    font-size: 16px;
    height: 40px;
    padding: 7px 15px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    line-height: 1.5;
  }

  //.w-33{
  //  max-width: 33%;
  //  width: 33%;
  //}
  .bucket-table-container {
    max-height: 60vh;

    .ant-table-row {
      margin: 4px 0;
      display: grid;

      .ant-table-cell {
        padding: 8px;
        background: #bebdbd;
        border-radius: 8px;

        &:hover {
          background: #dcdbdb;
        }

        .bucket-item {

          .bucket-item-name {
            width: calc(70%);
            font-size: 20px;
          }

          button {
            background: none;
            border: none;

            &:hover {
              color: #bdbdbd;
            }

            &:active {
              color: #838383;
            }
          }

        }
      }
    }
  }


  .bucket-table-container {
    padding: 8px;
    border: 2px dashed #efefef;
  }

}

